import { UspSectionStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { useContext } from 'react';
import { ColorContext } from '../../shared/providers/ColorContext';
import Container from '../Container/Container';
import styles from './UspSection.module.scss';

const UspSection = ({ blok }: UspSectionStoryblok) => {
    const { title, description } = blok;
    const hexColor = useContext(ColorContext);
    return (
        <Container
            blok={blok}
            code="standard"
            className={{ wrap: styles.container }}
            style={{ root: { backgroundColor: hexColor } }}
        >
            <h2 className={styles.title}>{title}</h2>
            <h3 className={styles.text}>{description}</h3>
        </Container>
    );
};

export default UspSection;
